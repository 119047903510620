<template>
  <div class="card p-1">
    <div class="custom-search">
      <div class="title head">
        <p class="h2" style="color: #558cef">รายงานวัสดุรายตัว</p>
      </div>
      <hr />
      <!-- advance search input -->
      <div class="custom-search row justify-content-center">
        <b-form-group class="col-12 col-md-3">
          <div class="align-items-center">
            <label class="mr-1">จากวันที่</label>

            <v-date-picker id="example-datepicker3" label="จากวันที่:" locale="th-TH" v-model="date1" @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-3">
          <div class="align-items-center">
            <label class="mr-1">ถึงวันที่</label>

            <v-date-picker id="example-datepicker3" label="ถึงวันที่:" locale="th-TH" v-model="date2" @update:from-page="getHeaderInputDate">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-input :value="formatDate(inputValue, 'date2')" v-on="inputEvents" />
              </template>
              <template #header-title>
                {{ headerInputDate }}
              </template>
            </v-date-picker>
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-3">
          <label>กลุ่มวัสดุ:</label>
          <div v-if="workgroup === null">
            <v-select v-model="equipmentGroup" :options="equipmentGroups" label="m_gid_m_gname" placeholder="เลือกกลุ่มวัสดุ" @input="changeRoute($event)" />
          </div>
        </b-form-group>
        <b-form-group class="col-12 col-md-3">
          <label>ชื่อวัสดุ:</label>
          <div v-if="equipmentGroup === null">
            <v-select v-model="equipmentGroup" :options="equipmentGroups" label="m_name" placeholder="เลือกชื่อวัสดุ" disabled />
          </div>
          <div v-else="equipmentGroup != null">
            <v-select v-model="typeG" :options="typeGs" label="m_name" placeholder="เลือกชื่อวัสดุ" />
          </div>
        </b-form-group>
      </div>
      <div class="row justify-content-between">
        <div class="row col-6 mb-1">
          <div class="ml-1">
            <b-button variant="success" @click="get_table_report"> ค้นหา </b-button>
          </div>
          <div class="ml-1">
            <b-button variant="danger" @click="cc_report"> ยกเลิก </b-button>
          </div>
          <!-- <div class="ml-1">
                        <b-button v-b-modal.modal-1 variant="outline-success"> ลงชื่อ </b-button>
                    </div> -->
          <div class="ml-1">
            <b-button variant="outline-primary" @click="exportExcel"> Export Excel </b-button>
          </div>
        </div>
        <div class="mr-2">
          <b-button variant="outline-primary" @click="exportpdf"> พิมพ์รายงานแยกตามกลุ่มวัสดุ PDF </b-button>
        </div>
      </div>

      <b-modal id="modal-1" title="ลงชื่อ สรุปรายการรับเข้า-เบิกจ่าย-คงเหลือวัสด" ok-only ok-title="ตกลง" no-close-on-backdrop>
        <b-card-text>
          <b-form-group label="ประธานกรรมการ" label-for="basicInput">
            <v-select @input="a_position(staff_name1)" :options="alluser" label="firstname" placeholder="ประธานกรรมการ" v-model="staff_name1" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department1" />
          </b-form-group>
          <b-form-group label="กรรมการ" label-for="basicInput">
            <v-select @input="b_position(staff_name2)" :options="alluser" label="firstname" placeholder="กรรมการ" v-model="staff_name2" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department2" />
          </b-form-group>
          <b-form-group label="กรรมการเเละเลขานุการ" label-for="basicInput">
            <v-select @input="c_position(staff_name3)" :options="alluser" label="firstname" placeholder="กรรมการเเละเลขานุการ" v-model="staff_name3" />
          </b-form-group>
          <b-form-group label="ตำแหน่ง" label-for="basicInput">
            <v-select :options="alluser" label="firstname" placeholder="ตำแหน่ง" v-model="department3" />
          </b-form-group>
        </b-card-text>
      </b-modal>
      <!-- <div class="row col-4">
            <div class="col-6">
              <label for="">สถานะ</label>
              <b-form-select v-model="status" :options="statusA" />
            </div>
            <div class="row">
              <div class="ml-1 mt-2">
                <b-button variant="success"> ค้นหา </b-button>
              </div>
              <div class="ml-1 mt-2">
                <b-button variant="danger"> ยกเลิก </b-button>
              </div>
            </div>
          </div> -->
    </div>

    <!-- table -->

    <b-overlay :show="show" rounded="sm">
      <b-table :items="items" :fields="fields" :tbody-tr-class="rowClass" responsive>
        <template #thead-top>
          <b-tr>
            <b-th colspan="3" class="text-center">
              <span class="text-center">{{ htable }}</span>
            </b-th>
            <b-th variant="primary" class="text-center" colspan="3">รับ</b-th>
            <b-th variant="danger" class="text-center" colspan="3">จ่าย</b-th>
            <b-th variant="danger" class="text-center" colspan="3">คงเหลือ</b-th>
          </b-tr>
        </template>

        <!-- <template #foot()="data">
                    <i class="text-danger">{{ data.label }}</i>
                </template> -->
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
  import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { VueGoodTable } from 'vue-good-table';
  import axios from 'axios';
  import API from '@/views/connectDB/condb.js';
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  import pdfMake from 'pdfmake';
  import pdfFonts from '../font/custom-fonts';
  import { DatePicker } from 'v-calendar';
  import dayjs from 'dayjs';
  import buddhistEra from 'dayjs/plugin/buddhistEra';
  dayjs.extend(buddhistEra);

  export default {
    components: {
      VueGoodTable,
      BAvatar,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BRow,
      BCol,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      vSelect,
      DatePicker,
    },
    data() {
      return {
        headerInputDate: '',
        date1: new Date(),
        date2: new Date(),
        date: {
          startDate: new Date(),
          endDate: new Date(),
        },
        staff_name1: '',
        staff_name2: '',
        staff_name3: '',
        selected: null,
        options: [{ value: null, text: 'เลือกปีงบประมาณ' }],
        equipment_code: '',
        e_number: '',
        serial_number: '',
        e_name: '',
        currentPage: 1,
        pageLength: 100,
        total: '',
        show: false,
        dir: false,
        workgroup: null,
        workgroups: [],
        equipmentGroup: null,
        equipmentGroups: [],
        department: '',
        departments: [],
        fields: [
          { key: 'eventdate', label: 'วันที่' },
          // { key: '-', label: '-' },
          { key: 'material_code', label: 'เลขทะเบียน' },

          { key: 'amount1', label: 'ปริมาณ' },
          { key: 'price1', label: 'ราคา' },
          { key: 'sum1', label: 'มูลค่า' },

          { key: 'amount2', label: 'ปริมาณ' },
          { key: 'price2', label: 'ราคา' },
          { key: 'sum2', label: 'มูลค่า' },

          { key: 'amount3', label: 'ปริมาณ' },
          { key: 'price3', label: 'ราคา' },
          { key: 'sum3', label: 'มูลค่า' },
        ],
        htable: '',
        items: [],
        rows: [],
        searchTerm: '',
        sumin: '',
        month: ['', 'มกราคม', 'กุมภาพันธ์ ', 'มีนาคม ', 'เมษายน ', 'พฤษภาคม ', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        alluser: [],
        department1: '',
        department2: '',
        department3: '',
        typeGs: [],
        typeG: '',
      };
    },
    computed: {
      example: {
        cache: false,
        get: function () {
          return Date.now() + this.msg;
        },
      },
    },
    created() {
      console.log('...............................................................................');
      const y = new Date().getFullYear();
      const yy = y + 543;
      this.options.push({ value: yy, text: yy });
      for (let index = 1; index < 5; index++) {
        this.options.push({ value: yy - index, text: yy - index });
      }
      this.workgroups_table();
      this.equipment_table();
      this.get_data_table_user();
      this.checkLogout();
    },
    methods: {
      rowClass(item, type) {
        if (!item || type !== 'row') return;
        if (item.material_code.substring(0, 1) == 'ต') return 'table-warning';
      },
      async changeRoute(event) {
        this.typeG = '';
        if (!event) {
        } else {
          const { access_token } = await this.access_token();
          const { m_gid } = event;
          // console.log(m_gid);
          const url = `${API}materialNames?_page=1&_limit=100&_sort=1&m_gid=${m_gid}`;
          const header = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.get(url, header);
          // console.log(res.data.message.total);
          const res_data = res.data.message.total;
          // console.log(res_data);
          let aa = Number(res_data) / 100;
          aa = Number(aa) + 1;
          const get_arr = [];
          let ints = 0;
          // console.log(parseInt(aa));
          aa = parseInt(aa);
          for (let index = 0; index < aa; index++) {
            const url = `${API}materialNames?_page=${index + 1}&_limit=100&_sort=1&m_gid=${m_gid}`;
            const header = {
              headers: {
                Authorization: await access_token,
              },
            };
            const res = await axios.get(url, header);
            // console.log(res.data.message.data);
            // get_arr.push(res.data.message.data)
            res.data.message.data.map((el) => {
              get_arr[ints] = {
                createdAt: el.createdAt,
                id: el.id,
                m_gid: el.m_gid,
                m_id: el.m_id,
                m_name: el.m_name,
                updatedAt: el.updatedAt,
              };
              ints += 1;
            });
          }
          // console.log(get_arr);
          // aa.forEach(async (element, index) => {

          //   const url = `${API}materialNames?_page=${index + 1}&_limit=100&_sort=1&m_gid=${m_gid}`;
          //   const header = {
          //     headers: {
          //       Authorization: await access_token,
          //     },
          //   };
          //   const res = await axios.get(url, header);
          //   console.log(res);
          // });
          this.typeGs = get_arr.map((element) => ({
            ...element,
            m_name: `${element.m_id} - ${element.m_name}`,
          }));
        }
      },

      async checkLogout() {
        console.log('ตรวจสอบออกจากระบบ');
        const { username } = await JSON.parse(localStorage.getItem('username_type'));
        console.log(username);
        axios
          .get('https://eoffice.niets.or.th/verify/auth', {
            headers: {
              'px-auth-token': `${username}`,
            },
          })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              console.log(' User อยู่ในระบบ');
            }
          })
          .catch((res) => {
            const { response } = res;
            console.log(response);
            if (response.status != 200) {
              console.log(' User ออกจากระบบเเล้ว');
              this.$router.push({ name: 'logoutUser' });
            }
          });
        console.log('-------------------------');
      },

      async a_position(value) {
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.department1 = res.data.message.data[0].dep_id;
      },
      async b_position(value) {
        // console.log(value.firstname);
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.department2 = res.data.message.data[0].dep_id;
      },
      async c_position(value) {
        // console.log(value.firstname);
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.department3 = res.data.message.data[0].dep_id;
      },
      async get_data_table_user() {
        const { access_token } = await this.access_token();
        const url = `${API}user?_page=1&_limit=100`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        const res_user = res.data.message.data.map((rs) => {
          return {
            ...rs,
            firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
          };
        });
        this.alluser = res_user;
      },
      exportpdf() {
        pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
        pdfMake.fonts = {
          // download default Roboto font from cdnjs.com
          Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
          },
          // Kanit Font
          Kanit: {
            // 3. set Kanit font
            normal: 'Sarabun-Regular.ttf',
            bold: 'Sarabun-Medium.ttf',
            italics: 'Sarabun-Italic.ttf',
            bolditalics: 'Sarabun-MediumItalic.ttf',
          },
        };
        // var headers = {
        //       fila_0: {
        //           col_1: {},

        //       },

        //   }
        const docDefinition = {
          pageMargins: [10, 70, 10, 50],
          pageSize: 'A4',
          pageOrientation: 'landscape',
          info: {
            title: 'รายงานวัสดุเเยกตามกลุ่มวัสดุ',
          },
          header: [
            {
              text: 'Client : สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)',
              style: 'header',
              margin: [10, 20, 0, 0],
            },
            {
              text: `Subject : ${this.typeG.m_name.split('-')[1]}`,
              style: 'header',
            },
            {
              text: `Period : ${parseInt(this.date.startDate.split('-')[2])}/${this.date.startDate.split('-')[1]}/${Number(this.date.startDate.split('-')[0]) + 543}`,
              style: 'header',
            },
            {
              text: `${this.typeG.m_name}`,
              style: 'header',
              margin: [10, 0, 0, 10],
            },
          ],
          // footer: {
          //   columns: [
          //     {
          //       text: `(ลงชื่อ)...................................................................(ประธานกรรมการ)
          //       (${this.staff_name1.firstname == undefined ? '...........................................................................' : this.staff_name1.firstname})
          //       ตำเเหน่ง: ${this.staff_name1.firstname == undefined ? '...........................................................................' : this.department1}
          //       `,
          //       alignment: 'center',
          //       style: 'text',
          //     },
          //     {
          //       text: `(ลงชื่อ)...................................................................(กรรมการ)
          //       (${this.staff_name2.firstname == undefined ? '...........................................................................' : this.staff_name2.firstname})
          //       ตำเเหน่ง: ${this.staff_name2.firstname == undefined ? '...........................................................................' : this.department2}
          //       `,
          //       alignment: 'center',
          //       style: 'text',
          //     },
          //     {
          //       text: `(ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
          //       (${this.staff_name3.firstname == undefined ? '...........................................................................' : this.staff_name3.firstname})
          //       ตำเเหน่ง: ${this.staff_name3.firstname == undefined ? '...........................................................................' : this.department3}
          //       `,
          //       alignment: 'center',
          //       style: 'text',
          //     },
          //   ],
          // },
          content: [
            // {
            //   text: `${this.equipmentGroup.m_gname}`,
            //   style: 'title_colume',
            //   alignment: '',
            // },
            {
              style: 'text',
              table: {
                headerRows: 3,
                widths: ['*', 'auto', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                body: [
                  [
                    {
                      text: 'วันที่',
                      style: 'tableHeader',
                      rowSpan: 3,
                    },
                    // {
                    //   text: '-',
                    //   style: 'tableHeader',
                    //   rowSpan: 3,
                    // },
                    {
                      text: 'เลขทะเบียน',
                      style: 'tableHeader',
                      rowSpan: 3,
                    },
                    // {
                    //   text: `จำนวนที่มีอยู่ต้นเดือน`,
                    //   style: 'tableHeader',

                    //   colSpan: 3,
                    // },
                    // {},
                    // {},
                    {
                      text: 'รับเข้า',
                      style: 'tableHeader',
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: 'เบิกของ',
                      style: 'tableHeader',
                      colSpan: 3,
                    },
                    {},
                    {},
                    {
                      text: 'คงเหลือ',
                      style: 'tableHeader',
                      colSpan: 3,
                    },
                    {},
                    {},
                    // {
                    //   text: 'คณะกรรมการตรวจนับวัสดุให้ความเห็น',
                    //   style: 'tableHeader',
                    //   colSpan: 3,
                    // },
                    // {},
                    // {},
                  ],
                  [
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: '',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    // {
                    //   text: '',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },

                    // {
                    //   text: `จำนวน`,
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                    // {
                    //   text: `ราคา`,
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                    // {
                    //   text: `ราคารวม`,
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                    {
                      text: 'จำนวน',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคา',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคารวม',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'จำนวน',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคา',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคารวม',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'จำนวน',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคา',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    {
                      text: 'ราคารวม',
                      style: 'tableHeader',
                      alignment: 'center',
                      rowSpan: 2,
                    },
                    // {
                    //   text: 'ถูกต้อง',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                    // {
                    //   text: 'ขาด',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                    // {
                    //   text: 'เกิน',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    //   rowSpan: 2,
                    // },
                  ],
                  [
                    {
                      text: ' ',

                      alignment: 'center',
                    },
                    // {
                    //   text: ' ',

                    //   alignment: 'center',
                    // },
                    {
                      text: ' ',

                      alignment: 'center',
                    },

                    // {
                    //   text: ` `,

                    //   alignment: 'center',
                    // },
                    // {
                    //   text: ` `,

                    //   alignment: 'center',
                    // },
                    // {
                    //   text: ` `,
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    // },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    {
                      text: ' ',
                      style: 'tableHeader',
                      alignment: 'center',
                    },
                    // {
                    //   text: ' ',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    // },
                    // {
                    //   text: ' ',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    // },
                    // {
                    //   text: ' ',
                    //   style: 'tableHeader',
                    //   alignment: 'center',
                    // },
                  ],

                  ...this?.items.map((res, index) => {
                    return [
                      { text: res.eventdate, style: 'text_center' },
                      // { text: ' ', style: 'text_left' },
                      { text: res.material_code, style: 'text_center' },
                      { text: res.amount1, style: 'text_right' },
                      { text: res.price1, style: 'text_right' },
                      { text: res.sum1, style: 'text_right' },
                      { text: res.amount2, style: 'text_right' },
                      { text: res.price2, style: 'text_right' },
                      { text: res.sum2, style: 'text_right' },
                      { text: res.amount3, style: 'text_right' },
                      { text: res.price3, style: 'text_right' },
                      { text: res.sum3, style: 'text_right' },
                      // { text: res.remainingAmount4, style: 'text_right' },
                      // { text: res.unitprice_vat4, style: 'text_right' },
                      // { text: res.sum4, style: 'text_right' },
                      // { text: ' ', style: 'text_center' },
                      // { text: ' ', style: 'text_center' },
                      // { text: ' ', style: 'text_center' },
                    ];
                  }),
                ],
              },
            },
          ],

          defaultStyle: {
            font: 'Kanit',
          },
          styles: {
            tableExample: {
              margin: [0, 0, 0, 0],
            },
            tableHeader: {
              bold: true,
              fontSize: 6,
              color: 'black',
              alignment: 'center',
            },
            header: {
              fontSize: 8,
              bold: true,
              margin: [10, 0, 10, 0],
            },
            title: {
              bold: true,
              fontSize: 9,
              color: 'black',
              margin: [0, 15, 0, 0],
            },
            title_colume: {
              bold: true,
              fontSize: 9,
              color: 'black',
              margin: [0, 0, 0, 0],
            },
            anotherStyle: {
              italics: true,
              alignment: 'center',
            },
            text_center: {
              alignment: 'center',
              fontSize: 6,
            },
            text_right: {
              alignment: 'right',
              fontSize: 6,
            },
            text_left: {
              alignment: 'left',

              fontSize: 6,
            },
            text: {
              fontSize: 9,
            },
          },
        };
        pdfMake.createPdf(docDefinition).download(`รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มวัสดุ ${dayjs(new Date()).format('DD-MM-BBBB-HHmm')}`);
      },
      exportExcel() {
        saveExcel({
          data: this.items,
          fileName: `รายงานผลการตรวจสอบวัสดุเเยกตามวัสดุ ${dayjs(new Date()).format('DD-MM-BBBB-HHmm')}`,
          columns: [
            {
              title: `Client : สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)`,
              headerCellOptions: { textAlign: '' },
              // children: [
              //     { title: `สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)`, headerCellOptions: { textAlign: "center" } },
              //     {},
              // ],
              children: [
                {
                  title: `Subject : ${this.typeG.m_name.split('-')[1]}`,
                  headerCellOptions: { textAlign: '' },
                  children: [
                    {
                      title: `Period : ${parseInt(this.date.startDate.split('-')[2])}/${this.date.startDate.split('-')[1]}/${Number(this.date.startDate.split('-')[0]) + 543}`,
                      headerCellOptions: { textAlign: '' },
                      children: [
                        {
                          title: `${this.typeG.m_name}`,
                          headerCellOptions: { textAlign: '' },
                          children: [
                            {
                              field: 'eventdate',
                              title: 'วันที่',
                            },
                            // { field: '-', title: '' },
                            {
                              field: 'material_code',
                              title: 'เลขทะเบียน',
                            },

                            {
                              title: 'รับเข้า',
                              headerCellOptions: { textAlign: 'center' },
                              children: [
                                { field: 'amount1', title: 'ปริมาณ' },
                                { field: 'price1', title: 'ราคา' },
                                { field: 'sum1', title: 'มูลค่า' },
                              ],
                            },
                            {
                              title: 'จ่าย',
                              headerCellOptions: { textAlign: 'center' },
                              children: [
                                { field: 'amount2', title: 'ปริมาณ' },
                                { field: 'price2', title: 'ราคา' },
                                { field: 'sum2', title: 'มูลค่า' },
                              ],
                            },
                            {
                              title: 'คงเหลือ',
                              headerCellOptions: { textAlign: 'center' },
                              children: [
                                { field: 'amount3', title: 'ปริมาณ' },
                                { field: 'price3', title: 'ราคา' },
                                { field: 'sum3', title: 'มูลค่า' },
                              ],
                            },
                          ],
                        },
                        {},
                      ],
                    },
                    {
                      title: ``,
                      headerCellOptions: { textAlign: 'center' },
                      children: [],
                    },
                  ],
                },
                {
                  title: ``,
                  headerCellOptions: { textAlign: 'center' },
                  children: [],
                },
              ],
            },
          ],
        });
      },
      formatFn: function (value) {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
      },
      cc_report() {
        this.items = [];
        this.date.startDate = '';
        this.date.endDate = '';
        this.equipmentGroup = null;
      },
      async sum_value(value) {
        console.log(value.toString().split('.'));
        console.log(value);
        var chk2 = await value.toString().split('.');
        var _return = '';
        const xx = chk2[1] ? chk2[1] : chk2[0];
        console.log(xx);
        var sum_chk = value.toString();
        console.log(sum_chk.indexOf('.'));
        if (chk2.length === 1) {
          _return = value.toString() + '0';
          console.log(_return);

          //console.log(sum_chk);
          _return = sum_chk;
        } else {
          const number = Number(sum_chk);
          const numberAsString = number.toString(); // แปลงเป็นสตริง
          const parts = numberAsString.split('.'); // แยกเป็นส่วนจำนวนเต็มและทศนิยม
          console.log(parts);

          const a1 = parts[1].substring(0, 1);
          const a2 = parts[1].substring(1, 2);
          const a3 = parts[1].substring(2, 3);
          console.log(a1);
          console.log(a2);
          console.log(a3);
          let result = number;
          // if (a2 <= 5 && a3 >= 5) {
          //   console.log('case1', value);
          //   result = value.toFixed(2);
          // } else if (a2 > 5 && a3 > 5) {
          //   console.log('case2', value);
          //   result = `${parts[0]}.${parts[1].substring(0, 2)}`; // ตัดทศนิยมให้เหลือ 2 ตำแหน่ง
          // }

          console.log(result); // แสดงผลลัพธ์เป็น "1456.12"
          _return = result;
        }
        return _return;
      },
      async get_table_report() {
        if (this.equipmentGroup === null) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `กรุณาเลือกวัสดุ`,
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
        if (this.typeG === '') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `กรุณาเลือกชื่อวัสดุ`,
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        }
        console.log(this.equipmentGroup);
        console.log(this.typeG);
        console.log(this.typeG.m_name.split('-')[1]);
        //ss
        //   this.show = true;
        const { access_token } = await this.access_token();
        const header = {
          headers: {
            Authorization: await access_token,
          },
        };
        console.log('...............................................................................');
        console.log(this.date.startDate);
        console.log(this.date.endDate);
        if (this.date.startDate != '' && this.date.endDate != '') {
          this.htable = this.typeG.m_name;
          const url = `${API}beginningBalance?startDate=${this.date.startDate}&m_id=${this.typeG.m_id}&_page=1&_limit=100`;
          const url2 = `${API}reportBalance?startDate=${this.date.startDate}&endDate=${this.date.endDate}&m_id=${this.typeG.m_id}&_page=1&_limit=100`;
          const res = await axios.get(url, header);
          const res2 = await axios.get(url2, header);
          console.log(url);
          console.log(res.data.message.data);
          console.log(url2);
          console.log(res2.data.message.data);

          const res_1 = [];
          const res_2 = [];
          let res_data = [
            {
              eventdate: '',
              '-': '',
              material_code: '',

              amount1: '',
              price1: '',
              sum1: '',

              amount2: '',
              price2: '',
              sum2: '',

              amount3: '0',
              sum3: '0',
              price3: '0',
            },
          ];
          let news_req_totalprice_vat = '';
          if (res.data.message.data.length != 0) {
            const total1 = res.data.message.total;
            let idx = Math.ceil(Number(total1) / 100);
            for (let index1 = 1; index1 <= idx; index1++) {
              console.log(index1);
              const url_pdf = `${API}beginningBalance?startDate=${this.date.startDate}&m_id=${this.typeG.m_id}&_page=${index1}&_limit=100`;
              console.log(url_pdf);
              const res_pdfs = await axios.get(url_pdf, header);
              console.log('data1', res_pdfs.data.message);
              res_1.push(...res_pdfs.data.message.data);
            }
            console.log(res_1);
            let sumamount1 = 0;
            let unitprice_vat2 = 0;
            let totalprice_vat2 = 0;

            let _s = '';
            let new_req_totalprice_vat = 0;
            let new_req_totalprice = 0;
            let sum_new_req_totalprice = 0;
            let del_new_req_totalprice = 0;
            for (let index = 0; index < res_1.length; index++) {
              if (res_1[index].eventtype == 'ตร') {
                sumamount1 += Number(res_1[index].amount);
                //800 +45  = 845
                totalprice_vat2 += Number(res_1[index].totalprice_vat);
                sum_new_req_totalprice += Number(res_1[index].totalprice_vat);
                unitprice_vat2 = Number(totalprice_vat2) / Number(sumamount1);
                console.log(new_req_totalprice_vat);

                console.log(new_req_totalprice);

                if (new_req_totalprice_vat != 0) {
                  new_req_totalprice = Number(res_1[index].totalprice_vat) + Number(new_req_totalprice_vat); /////////////////
                  console.log(res_1[index].totalprice_vat);
                  console.log(new_req_totalprice_vat);

                  totalprice_vat2 = Number(res_1[index].totalprice_vat) + Number(new_req_totalprice_vat);
                  unitprice_vat2 = Number(totalprice_vat2) / Number(sumamount1);
                } else {
                  console.log(Number(res_1[index].totalprice_vat));
                  new_req_totalprice += Number(res_1[index].totalprice_vat);
                }

                console.log(new_req_totalprice);
                // console.log(unitprice_vat2);
              } else {
                sumamount1 -= Number(res_1[index].amount);
                console.log('index', index);
                console.log(Number(sumamount1));
                console.log(Number(unitprice_vat2));
                new_req_totalprice_vat = Number(sumamount1) * Number(unitprice_vat2);

                new_req_totalprice -= Number(res_1[index].totalprice_vat); ///////////////////////////
                del_new_req_totalprice += Number(res_1[index].totalprice_vat);
                totalprice_vat2 = Number(sumamount1) * Number(unitprice_vat2);
                if (sumamount1 == 0) {
                  totalprice_vat2 = 0;
                  unitprice_vat2 = 0;
                  sumamount1 = 0;
                }
              }
            }
            console.log('----------------------------------------------------');
            console.log(sum_new_req_totalprice);
            console.log(del_new_req_totalprice);
            console.log('----------------------------------------------------');
            console.log(sumamount1);
            console.log(unitprice_vat2);
            console.log(new_req_totalprice_vat);
            console.log(new_req_totalprice);
            console.log(totalprice_vat2);
            console.log(unitprice_vat2);
            const total2 = res2.data.message.total;
            console.log(total2);
            //1007 = 0
            const ccc = total2 == 0 ? Number(sum_new_req_totalprice - del_new_req_totalprice) : new_req_totalprice;
            console.log(ccc);
            // !=0     new_req_totalprice

            // console.log(new_req_total);
            res_data[0]['sum3'] = totalprice_vat2; //ccc; // ccc; //Number(sum_new_req_totalprice - del_new_req_totalprice); // new_req_totalprice; //new_req_totalprice//totalprice_vat2;
            res_data[0]['price3'] = unitprice_vat2;
            res_data[0]['amount3'] = sumamount1; // sumamount1;
            console.log(res_data);

            let idx2 = Math.ceil(Number(total2) / 100);
            for (let index2 = 1; index2 <= idx2; index2++) {
              const url_pdf = `${API}reportBalance?startDate=${this.date.startDate}&endDate=${this.date.endDate}&m_id=${this.typeG.m_id}&_page=${index2}&_limit=100`;
              const res_pdfs = await axios.get(url_pdf, header);
              res_data.push(...res_pdfs.data.message.data);
            }
            console.log(res_data);

            let sumamount = 0;
            let _amount = 0;
            let _unitprice_vat2 = 0;
            let _unitprice_vat2ss = 0;
            let _totalprice_vat2 = 0;
            let sum_amount = 0;
            let _unitprice_vat = 0;
            let sum_totalprice_vat2 = 0;
            let _sum3 = 0;
            let _amount3 = 0;
            let _price_vat3 = 0;
            let _s2 = '';
            let new_req_totalprice_vat2 = 0;
            let new_req_totalprice_2 = 0;
            let sum_new_req_totalprice2 = 0;
            let del_new_req_totalprice2 = 0;
            const ras_s = res_data.map((el, index) => {
              console.log(el);
              if (index != 0) {
                if (el.material_code.substring(0, 1) == 'ต') {
                  console.log(el.amount);
                  sum_amount += Number(el.amount);
                  sum_new_req_totalprice2 += Number(el.totalprice_vat);
                  if (new_req_totalprice_vat2 != 0) {
                    new_req_totalprice_vat2 += Number(el.totalprice_vat);

                    console.log('new_req_totalprice_vat2', new_req_totalprice_vat2);
                    sum_totalprice_vat2 = new_req_totalprice_vat2;
                  } else {
                    console.log(new_req_totalprice_vat2);
                    sum_totalprice_vat2 += Number(el.totalprice_vat);
                  }
                  console.log('sum_amount', sum_amount);
                  console.log('sum_totalprice_vat2', sum_totalprice_vat2);

                  new_req_totalprice_2 = sum_totalprice_vat2; /////////////////////////////////////////

                  _sum3 = sum_totalprice_vat2;
                  _amount3 = sum_amount;
                  _price_vat3 = Number(sum_totalprice_vat2) / sum_amount;
                  // console.log('sum_totalprice_vat2',sum_totalprice_vat2);
                  // console.log('_sum3',_sum3)
                  // console.log('_amount3',_amount3)
                  console.log('_price_vat3', _price_vat3);
                  return {
                    ...el,
                    '-': '',
                    material_code: el.material_code,
                    eventdate: `${parseInt(el.eventdate.split('-')[2])} ${this.month[parseInt(el.eventdate.split('-')[1])]} ${Number(el.eventdate.split('-')[0]) + 543}`,
                    price1: Number(el.unitprice_vat),
                    amount1: Number(el.amount),
                    sum1: Number(el.amount) * Number(el.unitprice_vat),
                    amount2: '',
                    price2: '',
                    sum2: '',
                    amount3: _amount3,
                    sum3: _sum3,
                    price3: _price_vat3,
                  };
                } else {
                  _s2 = 'บ';
                  sum_amount -= Number(el.amount);
                  new_req_totalprice_vat2 = Number(sum_amount) * Number(_price_vat3);
                  del_new_req_totalprice2 += Number(el.totalprice_vat);
                  console.log('sum_amount', sum_amount);
                  console.log('_price_vat3', _price_vat3);
                  console.log('new_req_totalprice_vat2', new_req_totalprice_vat2);

                  console.log(new_req_totalprice_2);
                  new_req_totalprice_2 -= Number(el.totalprice_vat); ///////////////////////////
                  //  sum_amount -= Number(el.amount);
                  //   _totalprice_vat2 = Number(sum_amount) * _unitprice_vat;
                  //  _unitprice_vat = (Number(_totalprice_vat2) + Number(el.amount) * Number(el.unitprice_vat)) / (Number(el.amount) + _amount);
                  if (new_req_totalprice_vat2 == 0) {
                    _totalprice_vat2 = 0;
                    sum_totalprice_vat2 = 0;
                    console.log('NEW');
                  }
                  console.log(new_req_totalprice_2);
                  console.log(sum_new_req_totalprice2);
                  console.log(del_new_req_totalprice2);
                  console.log(Number(sum_new_req_totalprice2) - Number(del_new_req_totalprice2));

                  //  new_req_totalprice_2 = Number(sum_new_req_totalprice2) - Number(del_new_req_totalprice2);

                  return {
                    ...el,
                    '-': '',
                    material_code: el.material_code,
                    eventdate: `${parseInt(el.eventdate.split('-')[2])} ${this.month[parseInt(el.eventdate.split('-')[1])]} ${Number(el.eventdate.split('-')[0]) + 543}`,

                    amount1: '',
                    price1: '',
                    sum1: '',

                    price2: Number(el.unitprice_vat),
                    amount2: Number(el.amount),
                    sum2: el.amount == 0 ? '0' : Number(el.amount) * Number(el.unitprice_vat),

                    price3: Number(sum_amount) == '0' ? '0' : Number(el.unitprice_vat),
                    amount3: Number(sum_amount),
                    //new_req_totalprice <= 0 ? totalprice_vat2 : new_req_totalprice;
                    sum3: Number(sum_amount * el.unitprice_vat), //new_req_totalprice_2 <= 0 ? Number(sum_amount * el.unitprice_vat) : new_req_totalprice_2,
                  };
                }
              } else {
                new_req_totalprice_2 = el.sum3; /////////////////////////////////////////////////////////////////

                /// if (el.amount3 != 0) {
                sum_amount = el.amount3; /// 500
                //d  _unitprice_vat = el.price3;
                _price_vat3 = el.price3;
                sum_totalprice_vat2 = el.sum3;
                //  }

                return { ...el };
              }
            });

            this.items = ras_s.map((el) => {
              return {
                ...el,
                price1: el.price1
                  ? Number(el.price1)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                price2: el.price2
                  ? Number(el.price2)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                price3: el.price3
                  ? Number(el.price3)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : el.price3,
                sum1: el.sum1
                  ? Number(el.sum1)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                sum2: el.sum2
                  ? Number(el.sum2)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                sum3: el.sum3
                  ? Number(el.sum3)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : el.sum3,
              };
            });
            console.log(this.items);
          } else {
            // else 2
            const { total } = res2.data.message;
            let idx2 = Math.ceil(Number(total) / 100);
            for (let index2 = 1; index2 <= idx2; index2++) {
              const url_pdf = `${API}reportBalance?startDate=${this.date.startDate}&endDate=${this.date.endDate}&m_id=${this.typeG.m_id}&_page=${index2}&_limit=100`;
              const res_pdfs = await axios.get(url_pdf, header);
              res_2.push(...res_pdfs.data.message.data);
            }
            console.log(res_2);
            let sumamount = 0;
            let _amount = 0;
            let _unitprice_vat2 = 0;
            let _unitprice_vat2ss = 0;
            let _totalprice_vat2 = 0;
            let sum_amount = 0;
            let _unitprice_vat = 0;
            let sum_totalprice_vat2 = 0;
            let _sum3 = 0;
            let _amount3 = 0;
            let _price_vat3 = 0;
            let _s = '';
            let new_req_totalprice_vat = 0;
            const ras_s = res_2.map((el, index) => {
              if (el.material_code.substring(0, 1) == 'ต') {
                sum_amount += Number(el.amount);
                if (new_req_totalprice_vat != 0) {
                  new_req_totalprice_vat += Number(el.totalprice_vat);
                  sum_totalprice_vat2 = new_req_totalprice_vat;
                } else {
                  console.log(new_req_totalprice_vat);
                  sum_totalprice_vat2 += Number(el.totalprice_vat);
                }
                _sum3 = sum_totalprice_vat2;
                _amount3 = sum_amount;
                _price_vat3 = Number(sum_totalprice_vat2) / sum_amount;
                console.log(new_req_totalprice_vat);
                return {
                  ...el,
                  '-': '',
                  material_code: el.material_code,
                  eventdate: `${parseInt(el.eventdate.split('-')[2])} ${this.month[parseInt(el.eventdate.split('-')[1])]} ${Number(el.eventdate.split('-')[0]) + 543}`,
                  price1: Number(el.unitprice_vat),
                  amount1: Number(el.amount),
                  sum1: Number(el.amount) * Number(el.unitprice_vat),
                  amount2: '',
                  price2: '',
                  sum2: '',
                  amount3: _amount3,
                  sum3: _sum3,
                  price3: _price_vat3,
                };
              } else {
                _s = 'บ';
                sum_amount -= Number(el.amount);
                new_req_totalprice_vat = Number(sum_amount) * Number(_price_vat3);

                if (new_req_totalprice_vat == 0) {
                  _totalprice_vat2 = 0;
                  sum_totalprice_vat2 = 0;
                  console.log('NEW');
                }
                return {
                  ...el,
                  '-': '',
                  material_code: el.material_code,
                  eventdate: `${parseInt(el.eventdate.split('-')[2])} ${this.month[parseInt(el.eventdate.split('-')[1])]} ${Number(el.eventdate.split('-')[0]) + 543}`,

                  amount1: '',
                  price1: '',
                  sum1: '',

                  price2: Number(el.unitprice_vat),
                  amount2: Number(el.amount),
                  sum2: el.amount == 0 ? '0' : Number(el.amount) * Number(el.unitprice_vat),

                  price3: Number(sum_amount) == '0' ? '0' : Number(el.unitprice_vat),
                  amount3: Number(sum_amount),
                  sum3: Number(sum_amount * el.unitprice_vat),
                };
              }
              return { ...el };
            });
            console.log(ras_s);

            this.items = ras_s.map((el) => {
              return {
                ...el,
                price1: el.price1
                  ? Number(el.price1)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                price2: el.price2
                  ? Number(el.price2)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                price3: el.price3
                  ? Number(el.price3)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : el.price3,
                sum1: el.sum1
                  ? Number(el.sum1)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                sum2: el.sum2
                  ? Number(el.sum2)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : '',
                sum3: el.sum3
                  ? Number(el.sum3)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                  : el.sum3,
              };
            });
            return;
          }
        }

        this.show = false;
      },
      async equipment_table() {
        const { access_token } = await this.access_token();
        const header = {
          headers: {
            Authorization: await access_token,
          },
        };
        const url = `${API}materialGroups?_page=1&_limit=100`;
        const res = await axios.get(url, header);

        this.equipmentGroups = res.data.message.data.map((element) => {
          return {
            ...element,
            m_gid_m_gname: `${element.m_gid} - ${element.m_gname}`,
          };
        });
      },
      async workgroups_table() {
        const { access_token } = await this.access_token();
        const header = {
          headers: {
            Authorization: await access_token,
          },
        };
        const url = `${API}workgroups?_page=1&_limit=100&_sort=1`;
        const res = await axios.get(url, header);
        this.workgroups = res.data.message.data.map((element) => {
          return {
            ...element,
            work_gid_work_gname: `${element.work_gid} - ${element.work_gname}`,
          };
        });
      },
      async departments_table(value) {
        const { access_token } = await this.access_token();
        const header = {
          headers: {
            Authorization: await access_token,
          },
        };
        const url = `${API}departments?work_gid=${value.work_gid}`;
        const res = await axios.get(url, header);

        this.departments = res.data.message.data;
      },
      async access_token() {
        return (
          await axios.post(`${API}permit`, '', {
            headers: {
              Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
            },
          })
        ).data.message;
      },
      getHeaderInputDate(events) {
        switch (events.month) {
          case 1:
            this.headerInputDate = 'มกราคม ' + (events.year + 543);
            break;
          case 2:
            this.headerInputDate = 'กุมภาพันธ์ ' + (events.year + 543);
            break;
          case 3:
            this.headerInputDate = 'มีนาคม ' + (events.year + 543);
            break;
          case 4:
            this.headerInputDate = 'เมษายน ' + (events.year + 543);
            break;
          case 5:
            this.headerInputDate = 'พฤษภาคม ' + (events.year + 543);
            break;
          case 6:
            this.headerInputDate = 'มิถุนายน ' + (events.year + 543);
            break;
          case 7:
            this.headerInputDate = 'กรกฎาคม ' + (events.year + 543);
            break;
          case 8:
            this.headerInputDate = 'สิงหาคม ' + (events.year + 543);
            break;
          case 9:
            this.headerInputDate = 'กันยายน ' + (events.year + 543);
            break;
          case 10:
            this.headerInputDate = 'ตุลาคม ' + (events.year + 543);
            break;
          case 11:
            this.headerInputDate = 'พฤศจิกายน ' + (events.year + 543);
            break;
          case 12:
            this.headerInputDate = 'ธันวาคม ' + (events.year + 543);
            break;
          default:
            this.headerInputDate = 'ข้อมูลผิดพลาด';
            break;
        }
        //console.log(this.headerInputDate);
      },
      formatDate(date, val) {
        //console.log(val);
        let formatDate = '';
        let dete_val = '';
        if (date != null) {
          const _y = date.split('/');
          const _date = parseInt(_y[2]) + 543;
          formatDate = `${_y[0]}/${_y[1]}/${_date}`;
          dete_val = _y[2] + '-' + _y[1] + '-' + _y[0];
          switch (val) {
            case 'date1':
              this.date.startDate = dete_val;
              break;
            case 'date2':
              this.date.endDate = dete_val;
              break;
          }
        } else {
          formatDate = '-';
        }
        if (formatDate.toString() == '/undefined/NaN') {
          formatDate = '';
        }
        return formatDate;
      },
    },
  };
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
